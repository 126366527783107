// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../graphics/tick-dark-green.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal-content{max-height:calc(100vh - 78px);overflow:auto!important;padding-bottom:60px;padding-top:30px}.modal-content .normal-bullets ul{direction:inherit;list-style-type:disc;margin:0 0 1.25rem 1.25rem;padding:0}.modal-content .normal-bullets ul li{padding:20px 0}.modal-content .normal-bullets ul li:first-child{padding-top:0}.modal-content .normal-bullets ul li:last-child,.modal-content div>p:last-child{padding-bottom:0}.modal-content .normal-bullets ul li p{margin-left:12px}.modal-content .expander-custom-bullets ul{list-style-position:inside;list-style-type:none;padding:0}.modal-content .expander-custom-bullets ul li{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0;background-position-y:-1px;line-height:28px;margin:20px 0;min-height:28px;padding-left:42px}.modal-content .expander-custom-bullets ul li:first-child{margin-top:0}.modal-content .expander-custom-bullets ul:last-child li:last-child{margin-bottom:0}.modal-content .expander-custom-bullets ul li p{display:inline}.modal-content .expander-custom-bullets:first-child{margin:0}.modal-content div:first-child{padding-top:0}.modal-content div>p{padding-bottom:20px;padding-top:20px}.modal-content div>p:empty{padding:0}.modal-content div>p:first-child{padding-top:0}.modal-content ul li p b{font-weight:500;padding-bottom:8px}@media (min-width:767px){.modal-content div{padding:30px 146px 0 30px}}@media (max-width:767px){.modal-content div{padding:30px 20px 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
