import { CreateElement, VNode, VNodeChildren } from 'vue';
import { BLOCKS, MARKS, INLINES, NodeData } from '@contentful/rich-text-types';

import VideoPlayer from '../../video-block/VideoBlockWithNoImage.vue';
import QuoteCard from '../../cards/quote-card/QuoteCard.vue';
import ActionButtonSet from '../../buttons/action-button-set/ActionButtonSet.vue';
import CustomerLogos from '../../customerLogos/CustomerLogos.vue';
import ImageBlock from '../ImageBlock.vue';
import InfoImageCardRichText from '../../cards/info-image-card-rich-text/InfoImageCardRichText.vue';
import { isLocalURI, renderRichTextDocumentToHtml } from './richText.utils';
import { ContentModel, ImagePosition } from './richText.types';
import { createNavigationPathFromCMSLinktarget } from '~/shared/utils/url/url.utils';
import Testimonials from '~/ols-pages/course/testimonials/Testimonials.vue';
import EmbeddedCollapsible from '@/components/collapsible/EmbeddedCollapsible.vue';
import { CustomerLogo } from '~/components/customerLogos/CustomerLogos.types';

const wrapProtocolRelativeUrl = (url: string) => `https:${url}`;

const renderCell = (
  node: NodeData,
  key: string,
  h: CreateElement,
  next: any
): VNode => {
  const innerDiv = h(
    'div',
    {
      key,
      class: 'mb-10px',
      style: {
        whiteSpace: 'pre-line',
      },
    },
    next(node?.content[0]?.content, key, h, next)
  );

  return h('div', { key, class: 'table-cell p-10px' }, [innerDiv]);
};

const renderHyperlink = (
  node: NodeData,
  key: string,
  h: CreateElement,
  next: any
): VNode => {
  const { uri } = node.data;
  const { value } = node.content[0];

  if (String(uri).match(/menu-.*/)) {
    return h(
      'div',
      {
        key,
        attrs: { id: createNavigationPathFromCMSLinktarget(uri) },
      },
      next(node.content, key, next)
    );
  }

  const target = isLocalURI(uri) ? '_self' : '_blank';

  const readerLinkMessage =
    target === '_blank'
      ? h('span', { class: 'sr-only' }, '(Opens in a new window)')
      : '';

  return h(
    'a',
    {
      key,
      attrs: { href: uri, target },
    },
    [value, readerLinkMessage]
  );
};

const renderText = (
  node: NodeData,
  key: string,
  h: CreateElement,
  markRenderer: any
): VNode => {
  const { marks, value } = node;
  if (!marks.length) {
    return value.replace(/\u00A0/g, ' ');
  }

  const marksReversed = [...marks].reverse();
  return marksReversed.reduce(
    (aggregate, mark, i) =>
      markRenderer[mark.type]([aggregate], `${key}-${i}`, h),
    value.replace(/\u00A0/g, ' ')
  );
};

const renderEmbeddedBlock = (
  node: NodeData,
  key: string,
  h: CreateElement
): VNode | void => {
  const contentModel =
    node.data.target?.sys?.contentType?.sys?.id || 'noValueFound';
  const fields = node.data.target?.fields;

  if (contentModel === ContentModel.QUOTATION) {
    const { quoteAuthor = '', quoteAuthorsTitle = '', quoteText } = fields;
    const qouteContent = quoteText.content.map(
      (content: any) => content.content[0].value
    );
    return h(QuoteCard, {
      key,
      props: {
        quoteText: qouteContent,
        quoteAuthor,
        quoteAuthorsTitle,
      },
    });
  }

  if (contentModel === ContentModel.VIDEO_URL) {
    const { vimeoVideoUrl = '', youtubeVideoUrl = '' } = fields;
    if (vimeoVideoUrl) {
      return h(VideoPlayer, {
        key,
        props: {
          videoUrl: vimeoVideoUrl,
        },
      });
    }

    if (youtubeVideoUrl) {
      return h(VideoPlayer, {
        key,
        props: {
          videoUrl: youtubeVideoUrl,
          isYoutubeVideo: true,
        },
      });
    }
  }

  if (contentModel === ContentModel.CALL_TO_ACTION) {
    const {
      includeBookACall,
      includeCourseBrochure,
      bookACallTitle,
      bookACallDescription,
      courseBrochureTitle,
      courseBrochureDescription,
      brochureCourse,
      brochureLinkingUrl,
    } = fields;
    const slug = brochureCourse?.fields?.slug;

    return h(ActionButtonSet, {
      key,
      props: {
        includeBookACall,
        includeCourseBrochure,
        bookACallTitle,
        bookACallDescription,
        courseBrochureTitle,
        courseBrochureDescription,
        brochureLinkingUrl,
        slug,
        isWiderContainer: true,
      },
    });
  }

  if (contentModel === ContentModel.B2B_TESTIMONIALS) {
    const { testimonials } = fields;
    if (!testimonials) {
      return;
    }
    const B2BTestimonials = testimonials.map((testimonialContent: any) => {
      const {
        learnerName = null,
        learnerJobTitle = null,
        learnerCompanyName = null,
        testimonial,
        courseTaken = null,
        profilePicture = null,
      } = testimonialContent.fields;

      return {
        learnerName,
        learnerJobTitle,
        learnerCompanyName,
        testimonial,
        courseTakenSlug: courseTaken?.fields.slug,
        courseTakenName: courseTaken?.fields.title,
        profilePictureUrl: profilePicture?.fields.file.url,
      };
    });
    return h(Testimonials, {
      key,
      props: {
        content: {
          cardsBorderColor: '#6BBBAE',
          testimonials: B2BTestimonials,
        },
        titleHeadingClass: 'text-center md:text-left',
        isB2BTestimonials: true,
      },
    });
  }

  if (contentModel === ContentModel.SIDE_BY_SIDE_NO_OUTLINE) {
    const { image, imagePosition, text, buttonUrl, buttonText } = fields;
    if (!image || !text) {
      return;
    }
    return h(InfoImageCardRichText, {
      key,
      props: {
        imageSrc: wrapProtocolRelativeUrl(image.fields.file.url),
        altText: image.fields.description,
        useImageAsFirstItem: imagePosition === ImagePosition.LEFT,
        htmlContent: renderRichTextDocumentToHtml(text),
        isButtonEnabled: !!buttonUrl,
        buttonLink: buttonUrl,
        buttonText,
      },
    });
  }

  if (contentModel === ContentModel.EXPANDER_ITEM) {
    const {
      expanderContent,
      entryTitle,
      expanderTitle,
      slug,
      bulletPointStyle,
    } = fields;

    return h(EmbeddedCollapsible, {
      key,
      class: 'embedded-expander',
      props: {
        slug,
        expanderTitle: expanderTitle || entryTitle,
        expanderContent,
        isCheckBulletStyle: !!bulletPointStyle?.length,
      },
    });
  }

  if (contentModel === ContentModel.CUSTOMER_LOGOS) {
    const { logos, title, blurb } = fields;

    if (!logos || !title) {
      return;
    }

    const logosData = logos.map(
      (content: { fields: CustomerLogo }) => content.fields
    );

    return h(CustomerLogos, {
      key,
      props: { logos: logosData, title, blurb },
    });
  }
};

const renderEmbeddedAssets = (
  node: NodeData,
  key: string,
  h: CreateElement
): VNode | void => {
  const { fields } = node.data.target;

  if (fields && fields.file) {
    return h(ImageBlock, {
      key,
      props: {
        altText: fields.description,
        imageSrc: wrapProtocolRelativeUrl(fields.file.url),
      },
    });
  }
};

const renderTableRow = (
  node: NodeData,
  key: string,
  h: CreateElement,
  next: any
): VNode => {
  return h(
    'div',
    { key, class: 'table-row even:bg-page-grey' },
    next(node.content, key, h, next)
  );
};

const markRenderers = {
  [MARKS.BOLD]: (children: VNodeChildren, key: string, h: CreateElement) =>
    h('b', { key }, children),
  [MARKS.ITALIC]: (children: VNodeChildren, key: string, h: CreateElement) =>
    h('i', { key }, children),
  [MARKS.SUPERSCRIPT]: (
    children: VNodeChildren,
    key: string,
    h: CreateElement
  ) => h('sup', { key }, children),
  [MARKS.SUBSCRIPT]: (children: VNodeChildren, key: string, h: CreateElement) =>
    h('sub', { key }, children),
};

const nodeRenderers = {
  [BLOCKS.EMBEDDED_ENTRY]: (node: NodeData, key: string, h: CreateElement) =>
    renderEmbeddedBlock(node, key, h),
  [BLOCKS.EMBEDDED_ASSET]: (node: NodeData, key: string, h: CreateElement) =>
    renderEmbeddedAssets(node, key, h),
  [BLOCKS.TABLE]: (node: NodeData, key: string, h: CreateElement, next: any) =>
    next(node.content, key, h, next),
  [BLOCKS.TABLE_ROW]: (
    node: NodeData,
    key: string,
    h: CreateElement,
    next: any
  ) => renderTableRow(node, key, h, next),
  [BLOCKS.TABLE_CELL]: (
    node: NodeData,
    key: string,
    h: CreateElement,
    next: any
  ) => renderCell(node, key, h, next),
  [BLOCKS.TABLE_HEADER_CELL]: (
    node: NodeData,
    key: string,
    h: CreateElement,
    next: any
  ) => renderCell(node, key, h, next),
  [INLINES.HYPERLINK]: (
    node: NodeData,
    key: string,
    h: CreateElement,
    next: any
  ) => renderHyperlink(node, key, h, next),
  text: (node: NodeData, key: string, h: CreateElement, markRenderer: any) =>
    renderText(node, key, h, markRenderer),
};

export const richTextDocumentToVueConfig = () => {
  return {
    node: nodeRenderers,
    mark: markRenderers,
  };
};
